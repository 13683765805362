@import url("https://fonts.googleapis.com/css2?family=Alegreya+SC");

.betterTaxRegimeMainContainer {
  padding: 3rem 0;
  background-color: #f4fff1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 3rem 1.8rem;
  }

  .firstDescription {
    font-size: 1.1rem;
    font-weight: 700;
    color: #696871;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .heading {
    font-weight: 700;
    font-size: 1.7rem;

    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .secondDescription {
    font-size: 1rem;
    color: #696871;
  }

  .button {
    color: #000;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 0.5rem 2rem 0.6rem 2rem;
    border: 1px solid #000;
    border-radius: 25px;
    font-weight: 700;
    background-color: #fff;
    box-shadow: 4px 4px 4px #bbb8b8;
  }
}

.tableMainContainer {
  text-align: center;
  padding: 3rem 10rem;
  background-color: #fff;

  .mainHeading {
    font-size: 2.5rem;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
      padding: 0 1rem;
    }
  }

  .mainDescription {
    margin-bottom: 3rem;
    font-size: 1.2rem;
    color: #696871;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      padding: 0 1rem;
    }
  }

  .itrTable {
    border-color: #000;

    thead {
      font-size: 1.1rem;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }

      th {
        padding: 1rem 0;
        background-color: #fee8bc;

        @media screen and (max-width: 800px) {
          min-width: 120px;
        }
      }

      th:nth-child(1) {
        background-color: #fff;
        color: #e79f33;
        font-size: 1.2rem;
        @media screen and (max-width: 768px) {
          font-size: 0.9rem;
        }
      }
    }

    .verticalHeading {
      font-size: 1.1rem;
      font-weight: 700;
      background-color: #e3e27e;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    tbody {
      font-size: 1.1rem;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    .blueColor {
      color: #b714df;
    }

    .greenColor {
      color: #047a76;
    }

    .bgGreen {
      background-color: #72dccf;
    }
  }

  @media screen and (max-width: 1440px) and (min-width: 700px) {
    padding: 2rem 3rem;

    .mainHeading {
      font-size: 2.2rem;
    }

    .mainDescription {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 701px) {
    padding: 3rem 0 3rem 1rem;
  }
}

.calenderComponentContainer {
  background-color: #000;
  background-image: linear-gradient(rgba(27, 48, 70, 0.9),
      rgba(27, 48, 70, 0.9)),
    url(../../utils/images/ContactBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  .mainHeading {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    padding: 1rem;
    padding-bottom: 3rem;
    text-align: center;
  }

  .cardMainContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 767px) {
      padding: 1rem;
    }
  }

  .dateContainer {
    padding: 1rem 2rem;
    width: 300px;
    background-color: #fff;
    color: #000;
    border-radius: 20px;
    font-size: 1.4rem;
    font-weight: 600;

    @media screen and (max-width: 767px) {
      font-size: 1.1rem;
      width: 100%;
    }
  }

  .dateContainerFirst {
    box-shadow: 5px 5px 4px 3px #cc3366;
  }

  .dateContainerSecond {
    box-shadow: 5px 5px 4px 3px #25b6f5;
  }

  .dateContainerThird {
    box-shadow: 5px 5px 4px 3px #fac031;
  }

  .cardContainer {
    width: 300px;
    box-shadow: 5px 5px 4px 3px #979797;
    min-height: 320px;
    padding: 1.5rem;
    margin: 30px;
    margin-left: 0;
    background-color: #fff;
    color: #000;
    border-radius: 20px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 5rem;
    }

    .cardHeading,
    .cardDescription {
      font-size: 1.1rem;
      color: #19191b;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    .cardDescription {
      color: #5f5b5b;
    }
  }

  @media screen and (max-width: 1440px) {
    .mainHeading {
      font-size: 1.3rem;
    }

    padding: 3rem 1rem;
  }
}

.itrFormsmMainContainer {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3ffee;
  position: relative;

  @media screen and (min-width: 767px) {
    &::before {
      content: "";
      position: absolute;
      top: -30px;
      width: 100%;
      height: 122px;
      background-image: url(../../utils/images/topCurve.png);
    }
  }

  .mainHeading {
    font-size: 3rem;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 0 1.5rem;
    }
  }

  .mainDescription {
    font-size: 1.1rem;
    color: #696871;

    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 0.9rem;
      padding: 0 1.5rem;
    }
  }

  .formsCardMainContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem 8rem;

    @media screen and (max-width: 767px) {
      margin: 3rem 1rem;
    }

    .formCard {
      background-color: #fff;
      width: 400px;
      border: 1px solid #b5b3b3;
      border-radius: 30px;
      box-shadow: 8px 8px 4px 3px #979797;
      margin: 10px;
      padding: 1rem 1.5rem;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      img {
        width: 60px;
        height: 60px;
      }

      .heading {
        font-size: 1.3rem;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          font-size: 1.1rem;
        }
      }

      .cardHeading {
        margin-top: 10px;
        font-size: 1.2rem;
        color: #19191b;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }

      .cardDescription {
        color: #19191b;
        font-size: 1.1rem;
        @media screen and (max-width: 768px) {
          font-size: 0.9rem;
        }
      }

      &:hover {
        background-color: #06aead;
        color: #fff;

        .cardHeading {
          color: #fff;
        }

        .cardDescription {
          color: #fff;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) and (min-width: 700px) {
    .mainHeading {
      font-size: 2.2rem;
    }

    .mainDescription {
      font-size: 0.9rem;
    }

    .formsCardMainContainer {
      margin: 1rem 4rem;

      .formCard {
        width: 300px;

        .heading {
          font-size: 1.1rem;
        }

        .cardHeading {
          font-size: 0.9rem;
        }

        .cardDescription {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.itrFilingMainContainer {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  background-image: linear-gradient(rgba(27, 48, 70, 0.9),
      rgba(27, 48, 70, 0.9)),
    url(../../utils/images/ItrVedioBg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 6rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-image: url(../../utils/images/bottomCurve.png);
  }

  .vedioContainer {
    .solutionsVedio {
      border-radius: 30px;
      width: 650px;
      height: 450px;

      @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 500px;
        height: 350px;
      }

      @media screen and (max-width: 768px) {
        height: 300px;
        width: 100%;
        border-radius: 20px;
      }
    }
  }

  .textContainer {
    margin-left: 15rem;

    @media screen and (max-width: 1440px) and (min-width: 767px) {
      margin: 0 4rem;
      margin-bottom: 5rem;
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      margin: 0 2rem;
      margin-bottom: 5rem;
      text-align: center;
    }

    .heading {
      font-size: 2.4rem;
      color: #fff;
      border-bottom: 2px solid #fff;

      @media screen and (max-width: 1440px) {
        font-size: 1.5rem;
        font-weight: 700;
      }

      @media screen and (max-width: 1200px) {
        font-size: 1.3rem;
        border-bottom: none;
      }
    }

    .subHeading {
      font-size: 1.8rem;
      color: #fff;

      @media screen and (max-width: 1440px) {
        font-size: 1.5rem;
        font-weight: 700;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
      }
    }

    .mobileInputContainer {
      display: flex;
      z-index: 20;
      width: 450px;
      justify-content: space-between;
      align-items: center;

      .errorBorder {
        border: 2px solid #ff0000;
      }

      .mobileInput {
        width: 290px;
        height: 50px;
        border-radius: 10px;
        padding-left: 20px;
        z-index: 20;
        cursor: text;
      }

      .mobileInputButton {
        width: 150px;
        border-radius: 10px;
        background-color: #039cbd;
        color: #fff;
        font-weight: 600;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;

        .mobileInput {
          width: 100%;
          margin-top: 20px;
        }

        .mobileInputButton {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }

    .errorMsg {
      color: #ff0000;
      height: 10px;
      font-size: 1rem;
      font-weight: 600;
      padding: 0;
      padding-left: 10px;
    }

    // .description{
    //     color: #fff;
    //     font-size: 1.5rem;
    //     @media screen and (max-width: 1440px){
    //         font-size: 1.2rem;
    //     }
    // }
    .buttonContainer {
      font-family: "Alegreya SC", serif;
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: 1440px) {
        justify-content: center;
        margin-left: 5px;
      }

      a {
        text-decoration: none;
        color: #000;
      }

      .callNow,
      .whatsapp {
        font-size: 1.3rem;
        font-weight: 700;
        border-radius: 20px;
        width: 220px;
        height: 60px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        z-index: 10;
        cursor: pointer;
        background-color: #fff;

        @media screen and (max-width: 1440px) and (min-width: 767px) {
          width: 180px;
          height: 40px;
          border-radius: 10px;
          font-size: 1rem;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 20px;
          font-size: 1rem;
          border-radius: 10px;
        }

        span {
          margin-left: 4.5rem;

          @media screen and (max-width: 1440px) and (min-width: 767px) {
            margin-left: 3rem;
          }
        }

        img {
          position: absolute;
          width: 80px;
          height: 80px;

          @media screen and (max-width: 1440px) and (min-width: 767px) {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .callingNumber,
    .discount {
      font-family: "Alegreya SC", serif;
      display: flex;
      height: 60px;
      width: 450px;
      border-radius: 20px;
      margin-top: 15px;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      position: relative;

      @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 370px;
        border-radius: 10px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        // flex-wrap: wrap;
        height: 75px;
        border-radius: 10px;
      }

      p {
        margin-left: -10px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 1.4rem;
        font-weight: 700;

        @media screen and (max-width: 1440px) {
          font-size: 1.1rem;
        }
      }
    }

    .callingNumber {
      cursor: pointer;
      color: #000;
      text-decoration: none;
      z-index: 10;
      padding: 0 30px 0 15px;

      img {
        height: 50px;

        @media screen and (max-width: 1440px) and (min-width: 767px) {
          height: 30px;
        }
      }
    }

    .discount {
      height: 130px;

      @media screen and (max-width: 1440px) and (min-width: 767px) {
        height: 100px;
      }

      @media screen and (max-width: 767px) {
        height: 120px;
      }

      img {
        height: 130px;

        @media screen and (max-width: 1440px) and (min-width: 767px) {
          height: 80px;
        }
      }

      .referral {
        p {
          font-size: 1.5rem;
          margin-top: 0;
          margin-bottom: 0;

          @media screen and (max-width: 1440px) {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

.itrFilingInCitiesMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4fff1;
  padding: 5rem;

  .heading {
    font-size: 2.5rem;
    font-weight: 600;
    word-spacing: 0.6rem;
  }

  .descriptionContainer {
    .listContainer {
      padding-top: 2rem;
      padding-left: 5rem;
      width: 400px;

      ul {
        font-size: 1.3rem;
        color: #000;

        li {
          cursor: pointer;

          &:hover {
            color: #35c2b6;
          }

          a {
            text-decoration: none;
            color: #000;

            &:hover {
              color: #35c2b6;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440px) and (min-width: 700px) {
    padding: 5rem 0;

    .descriptionContainer {
      .listContainer {
        width: auto;
        padding-left: 1rem;
      }
    }
  }

  @media screen and (max-width: 701px) {
    padding: 4rem 1rem;

    .heading {
      font-size: 1.4rem;
      word-spacing: normal;
    }

    .descriptionContainer {
      .listContainer {
        padding-left: 3rem;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        width: 100%;

        ul {
          font-size: 1rem;
        }
      }
    }
  }
}