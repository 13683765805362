.customerReviewMainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    padding-bottom: 0;
    background-color: #DAF3E1;
    @media screen and (max-width:768px) {
        padding: 5rem 1rem 0;
        text-align: center;
     }
     position: relative;
    //  &::before{
    //     content: '';
    //     position: absolute;
    //     top: -20px;
    //     width: 100%;
    //     height: 122px;
    //     background-image: url(../../../utils/images/topCurve.png);
    //     @media screen and (max-width: 768px) {
    //         background-image: none;
    //      }
    // }
    .heading{
        font-size: 3rem;
        font-weight: 700;
        margin: 0;
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            font-size: 2.3rem;
        }
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            margin-bottom: 8px;
         }
    }
    .description{
        font-size: 1.2rem;
        font-weight: 500;
        color: #696871;
        @media screen and (max-width: 1440px) {
            font-size: 0.9rem;
         }
    }
}

.customerReviewCard{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: #000;
    width: 300px;
    min-height: 385px;
    padding: 1.2rem 1.3rem;
    background-color: #fff;
    border: 2px solid #dddbdb;
    border-radius: 30px;
    margin: 10px;
    justify-content: space-between;
    @media screen and (min-width:1799px) {
        width: 360px;
        margin: 20px;
    }
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 230px;
        min-height: 340px;
    }
    @media screen and (max-width:767px) {
        // width: 99%;
        padding: 2rem 1rem;
        margin: 0;
    }

    .rating{
        display: flex;
        align-items: center;
        height: 50px;
        img{
            width: 50px;
            height: 50px;
            @media screen and (max-width: 1440px) and (min-width: 767px) {
                width: 30px;
                height: 30px;
            }
        }
        p {
            color: #F04037;
            font-size: 1.6rem;
            @media screen and (max-width: 1440px)  {
                font-size: 1rem;
                margin-top: 10px;
            }
        }
    }
    .cardDescription{
        font-size: 0.9rem;
        font-style: italic;
        font-weight: 500;
        line-height: 1.3rem;
        margin-top: 8px;
        @media screen and (max-width: 1440px) {
            font-size: 0.6rem;
        }
    }
    .cutomerInfoContainer{
        display: flex;
        .customerImg{
            font-size: 1.2rem;
            font-weight: 700;
            width: 35px;
            height: 35px;
            border: 1px solid #000;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
        .customerInfo{
            margin-top: 4px;
            .customerName{
                font-size: 1.1rem;
                font-weight: 600;
                margin: 0;
                @media screen and (max-width: 1440px) {
                    font-size: 0.8rem;
                }
            }
            .extraInfo{
                font-size: 1rem;
                color: #8c8b93;
                margin-top: 8px;
            }
        }
    }
}