.faqSectionWrapper{
    background-color: #E4DCF3;
    padding: 3rem 0 5rem;
.faqMainContainer{
    margin: 1rem 13rem;
    .mainHeading{
        text-align: center;
        font-size: 2.3rem;
        font-weight: 700;
        @media screen and (max-width:768px) {
            font-size: 1.4rem;
         }
    } 
    .mainDescription{
        text-align: center;
        font-size: 1rem;
        color: #696871;
        @media screen and (max-width:768px) {
            margin-bottom: 2.5rem;
         }
    }
    .faqCard{
        background-color: #fff;
        margin-bottom: 1rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        .cardHeader{
            background-color: #fff;
            border-radius: 15px;
            .heading{
                background-color: #fff;
                border: 0;
                font-weight: 600;
                font-size: 1.1rem;
                width: 100%;
                text-align: start;
                display: flex;
                align-items: center;
                padding: 0;
                @media screen and (max-width:768px) {
                    font-size: 0.8rem;
                 }
                span{
                    font-size: 1.8rem;
                    margin-right: 20px;
                    color: #127378;
                    
                    }
                
            }
        }
        
    }
    @media screen and (max-width: 1440px) {
            .mainHeading{
                font-size: 1.4rem;
            }
            .mainDescription{
                font-size: 0.9rem;
            }
            .faqCard{
                border-radius: 8px;
                .cardHeader{
                    border-radius: 8px;
                    .heading{
                        font-size: 0.9rem;
                        span{
                            font-size: 1rem;
                        }
                    }
                    
                }
                .description{
                    font-size: 0.8rem;
                }
            }
    }
    @media screen and (max-width:768px) {
        margin: 1rem 2rem;
    }
}
}