.footerMainContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #000;
    color: #fff;

    .footerFirstRow{
        display: flex;
        justify-content: space-between;
        padding: 2rem 2rem 0 2rem !important;
        margin-bottom: -1.7rem;
        @media screen and (max-width:768px) {
         padding: 2rem 1rem !important;
        }
    }


    .copyRightSection{
        margin-top: 3rem;
        display: flex;
        align-items: center;
        border-top:1px solid #fff;
        padding: 0.1rem 3rem;
        .privacyPolicy{
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            li{
            padding: 0 1rem;
            }
        }
        @media screen and (max-width: 1440px) {
            padding: 0.1rem 1rem;
            p{
                font-size: 0.8rem;
            }
            .privacyPolicy{
                font-size: 0.8rem;
            }
        }
        @media screen and (max-width:701px) {
            text-align: center;
            .privacyPolicy{
                padding: 0;
                justify-content: center;
            }
           }
    }
}

.footerFirstInfo{
    font-size: 1.1rem;
    @media screen and (max-width: 768px) {
        font-size: 0.9rem;
    }
}

.footerBoldSubHeading{
    font-size: 1.1rem;
    font-weight: 600; 
    color: #fff;
    margin-right: 10px;
}

.footerLinks{
    @media screen and (max-width: 1440px) and (min-width: 700px) {
        width: 18%;
        .sectionHeading{
            font-size: 0.9rem;
        }
        .sectionDescription{
            font-size: 0.6rem;
            .footerWhatsapp{
                img{
                    width: 40px;
                    height: 30px;
                }
            }
            .footerBoldSubHeading{
                .followIcons{
                    li{
                    img{
                        width: 30px;
                        height: 30px;
                    }
                }
                }
            }
        }
    }
}
.firstSection{
    @media screen and (max-width: 1440px) and (min-width: 700px) {
        width: 25%;
    }
}
    .sectionHeading{
        font-size: 1.3rem;
        text-decoration: underline;
        padding-bottom: 2rem;
        color: #efeded;
        @media screen and (max-width: 768px){
            font-size: 1.2rem;
        }
    }
    .sectionDescription{
        list-style: none;
        padding: 0;
        font-size: 0.9rem;
        color: #efeded;
        
        & li{
            margin-bottom: 1.5rem;
        }

        .footerWhatsapp{
            padding: 0.3rem 2rem 0.3rem 0.3rem;
            background-color: #323050;
            border-radius: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            a{
                color: #fff;
                text-decoration: none;
            }
            img{
                margin-right: 15px;
                width: 60px;
                height: 50px;
            }
        }

        .followIcons{
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin-top: 10px;
            padding-left: 0;
            li{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 5px;
                cursor: pointer;
                a{
                    color: #fff;
                }
                img{
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }