.whatsappIcon{
    bottom: 15px;
    position: fixed;
    right: 15px;
    z-index: 999;
}

.whatasppImg{
    width: 75px;
    height: 60px;
    @media screen and (max-width: 600px) {
        width: 55px;
        height: 45px;
    }
}