.consulationMainContainer{
    background-color: #FAFAFA;
    display: flex;
    justify-content: space-around;
    padding: 5rem 0;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        padding: 3rem 0;
     }
    @media screen and (max-width:1024px) {
        flex-direction: column-reverse;
        padding: 3rem 0;
    }
    position: relative;
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        background-image: url(../../../utils/images/bottomCurve.png);
    }
}
.consultationFormContainer{
    width: 700px; 
    padding-top: 5rem;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
       width: 450px;
    }
    @media screen and (max-width:768px) {
        padding-top: 2rem;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .consultationTitle{
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 2rem;
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            font-size: 2rem;
         }
        @media screen and (max-width:768px) {
            font-size: 1.4rem;
            text-align: center;
        }
    }
    .consultationForm{
        display: flex;
        flex-direction: column;
        width: 600px;
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            width: 450px;
         }
        @media screen and (max-width:768px) {
            width: 85%;
        }
        .consultationFormNameContainer{
            display: flex;
            margin-bottom: 30px;
            border: 2px solid #dfdfdf;
            background-color: #fff;
            width: 550px;
            border-radius: 10px;
            @media screen and (max-width: 1440px) and (min-width: 767px) {
                width: 450px;
             }
            @media screen and (max-width:768px) {
                width: 100%;
            }
            &:active{
                border: 2px solid #000;
            }
            &:hover{
                border: 2px solid #000;
            }
        }
        .consultationFormMobileContainer{
            display: flex;
            margin-bottom: 30px;
            border: 2px solid #dfdfdf;
            background-color: #fff;
            border-radius: 10px;
            width: 550px;
            @media screen and (max-width: 1440px) and (min-width: 767px) {
                width: 450px;
             }
            &:active{
                border: 2px solid #000;   
            }
            &:hover{
                border: 2px solid #000;
            }
            @media screen and (max-width:768px) {
                width: 100%;
            }
        }
        .consultationFormName{
            width: 550px;
            height: 80px;
            border-radius: 10px;
            font-size: 1.2rem;
            font-weight: 500;
            border: 0;
            outline: 0;
            padding-left: 2rem;
            box-sizing: border-box; 
            &::placeholder{
                font-size: 1.1rem;
                text-align: center;
            }
            @media screen and (max-width: 1440px) and (min-width: 767px) {
                width: 450px;
                height: 60px;
                font-size: 1rem;
                padding-left: 0;
             }
            @media screen and (max-width:768px) {
                width: 100%;
                height: 70px;
                &::placeholder{
                    text-align: start;
                }
            }
        }
        .consultationFormMobile{
            width: 550px;
            height: 80px;
            border-radius: 10px;
            font-size: 1.2rem;
            font-weight: 500;
            border: 0;
            outline: 0; 
            padding-left: 2rem;
            box-sizing: border-box; 
            &::placeholder{
                font-size: 1.1rem;
                text-align: center;
            }
            @media screen and (max-width: 1440px) and (min-width: 767px) {
                width: 450px;
                height: 60px;
                font-size: 1rem;
                padding-left: 0;
             }
            @media screen and (max-width:768px) {
                width: 100%;
                height: 70px;
                &::placeholder{
                    text-align: start;
                }
            }
        }
        .consultationFormBtn{
            height: 80px;
            border-radius: 10px;
            font-size: 1.5rem;
            font-weight: 500;
            border: 0;
            color: #fff;
            background-color: #06aead;
            border: 2px solid #dfdfdf;
            width: 555px;
            cursor: pointer;
            &:hover{
                background-color: #049090;
            }
            @media screen and (max-width: 1440px) and (min-width: 767px) {
                width: 450px;
                height: 60px;
                font-size: 1rem;
             }
            @media screen and (max-width:768px) {
                font-size: 1.2rem;
                height: 70px;
                width: 100%;
            }
        }
    }
}

.consultaionImgContainer {
    padding-right: 7rem;
    @media screen and (max-width:768px) {
      padding-right: 0;
       margin-top: 2rem;
    }
 img{
    width: 140%;
    height: 100%;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
       width: 100%;
     }
    @media screen and (max-width:768px) {
        width: 100%;
        height: 100%;
    }
}
}