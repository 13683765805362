.whyTaxEasyContainer{
    display: flex;
    justify-content: center;
    background-color: #F7FFD0;
    padding-top: 3rem;
    @media screen and (max-width:1024px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
     }
    @media screen and (max-width:768px) {
       flex-direction: column;
       padding: 3rem 1rem;
    }
    position: relative;
    // &::after{
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;
    //     height: 100px;
    //     background-image: url(../../../utils/images/bottomCurve.png);
    // }
    .imageContainer{
        margin-left: 5rem;
        @media screen and (max-width:767px) {
           margin-left: 1rem;
           img{
            width: 100%;
           }
        } 
    }
    .heading{
        font-size: 3rem;
        font-weight: 700;
        color: #000;
        margin: 0;
        margin-top: 4rem;
        @media screen and (max-width: 1440px) {
            font-size: 2rem;
        }
        @media screen and (max-width:1024px) {
            margin-top: 1rem;
        }
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }
    .description{
        margin-top: 15px;
        font-size: 1.1rem;
        font-weight: 500;
        color: #696871;
        @media screen and (max-width:1440px) {
            font-size: 0.8rem;
        }
    }
    @media screen and (max-width:768px) {
        text-align: center;
    }
}

.infoCardContainer{
    display: flex;
    flex: 0 1 50%;
    flex-wrap: wrap;
    margin-top: 1rem;
    justify-content: center;
    @media screen and (max-width:1024px) and (min-width: 769px) {
        flex: 100%;
        padding: 0 3rem;
    }
}

.customerCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 30px;
    margin: 0 15px;
    width: 260px;
    height: 270px;
    padding: 1.5rem 2.3rem;
    box-shadow: 2px 2px 2px 2px #8a8787;
    @media screen and (min-width:1800px) {
        width: 300px;
        padding: 3rem 1rem;
    }
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 230px;
        height: 230px;
    }
    @media screen and (max-width:768px) {
        width: 100%;
        margin: 1rem 2rem;
        box-sizing: border-box;
    }
    
    img{
        height: 80px;
    }
    .cardTitle{
        text-align: center;
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        margin-top: 1.5rem;
        @media screen and (max-width: 1440px) {
            font-size: 1rem;
        }
    }
    .cardDescription{
        font-size: 1rem;
        font-weight: 600;
        color: #696871;
        text-align: center;
        @media screen and (max-width: 1440px) {
            font-size: 0.8rem;
        }
    }
}

.gap{
    margin-top: -70px;
    @media screen and (max-width: 1023px) {
        margin-top: 15px;
    }
}
