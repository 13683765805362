.thankyouContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .heading{
        font-size: 2.5rem;
        font-weight: 900;
        text-shadow: 10px 10px 10px #195d3f;
        @media screen and (max-width: 768px) {
            font-size: 2rem;
        }
    }

    .thankyouImg{
        @media screen and (max-width: 768px) {
            width: 100px;
            height: 100px;
        }
    }

    .description{
        margin-top: 20px;
        margin-bottom: 80px;
        font-size: 1.5rem;
        max-width: 700px;
        text-align: center;
        font-style: oblique;
        @media screen and (max-width: 768px) {
            font-size: 1rem;
            padding: 30px;
        }
    }
}