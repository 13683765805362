@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC');

.mainHeadingAboutPageContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    background-color: #144148;
    text-align: center;
    font-family: 'Alegreya SC', serif;
    .heading{
        font-size: 1.8rem;
        margin: 0;
        color:#fff;
        font-weight: 400;
    }
    .description{
        margin-top: 10px;
        font-size: 1.2rem;
        color:#fff;
    }
    @media screen and (max-width: 1440px) {
        .heading{
            font-size: 1.2rem;
        }
        .description{
            font-size: 0.9rem;
        }
    }
    @media screen and (max-width:1024px) {
        padding: 2rem;
    }
}

.gstInfoContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #144148;
    padding: 2rem 8rem;
    color: #fff;
    font-size: 1.1rem;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        padding: 1rem 2rem;
        font-size: 0.9rem;
    }
    @media screen and (max-width:768px) {
        width: auto;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        padding: 1rem;
        font-size: 1rem;
    }

    & p::before{
        content: '✔';
    }
    & p span{
        margin-left: 10px;
    }

    .callingInfo{
        padding: 1.2rem;
        background-color: #fff;
        color: #000;
        font-size: 1.2rem;
        font-weight: 600;
        width: 260px;
        border-radius: 10px;
        margin-left: 20px;
        a{
            color: #000;
            text-decoration: none;
        }
        @media screen and (max-width:1600px) {
            width: 300px;
        }
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            width: 250px;
            padding: 1rem;
            font-size: 0.9rem;
        }
        @media screen and (max-width:768px) {
            width: 90%;
            margin: 0;
            padding: 1rem;
            font-size: 1rem;
        }
    }
}

.mainGoogleRatingContainer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10rem;
    background-color: #144148;
    .heading{
        font-size: 1.3rem;
        color: #fff;
        margin: 0;
    }
    .description{
        margin: 0;
        color: #F1D900;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        img{
            width: 50px;
            height: 50px;  
        }
    }
}

.mainGoogleRatingContainerWithMobile{
    .heading{
        margin-top: 25px;
    }
    .description{
        margin-top: -20px;
        img{
            margin-left: -10px;
        }
    }
}

.mobileInputContainer{
    margin-top: 0;
}

.carouselContainer{
    ul{
    padding-top: 2rem;
    padding-bottom: 4rem;
    background-color: #DAF3E1;
    // @media screen and (max-width: 500px) {
    //     li{
    //     margin-left: 15px;
    //     margin-right: 15px;
    //     width: 300px !important;
    //     }
    // }
    }
}

.imageCard{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    padding: 1.2rem;
    border: 2px solid #cfcfcf;
    width: 270px;
    height: 270px;
    overflow: hidden;
    border-radius: 30px;
    box-shadow: 4px 4px 1px 1px #d4d1d1;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 200px;
        height: 200px;
    }
    img{
       padding: 1rem;
       width: inherit;
    }
}

.imageComponentSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 0;
    background-color: #fff;
}

.stepsCircle {
    border: 0;
    padding: 0;
    margin-left: -28px;
    margin-right: -28px;
    img{
        width: 150px;
        height: 150px;
    }
   float:left;
   @media screen and (max-width: 1440px) and (min-width: 700px) {
    img{
        width: 100px;
        height: 100px;
    }
}
}
.stepsLine{
    margin-top: 47px;
    width: 350px;
    height: 25px;
    border-bottom: 2px dotted #929090;
    float:left;
    @media screen and (max-width: 1440px) and (min-width: 700px) {
        width: 280px;
        margin-top: 28px;
    }
}

.stepsMobile{
    h4{
        margin-top: 20px !important;
    }
    img{
        border: 0;
        padding: 0;
    }
}

.clear{
    clear: both;
}

.nameofCitiesMainContainer{
    font-family: 'Alegreya SC', serif;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #144148;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    @media screen and (max-width: 1440px) and (min-width: 700px) {
        font-size: 0.8rem;
    }
    @media screen and (max-width:701px) {
        display: none;
    }
    .gifContainer{
        display: flex; 
        height: 100px;
        @media screen and (max-width: 1440px) and (min-width: 700px) {
            height: 50px;
        }
        @media screen and (max-width:701px) {
            display: none;
        }
    }
    .cityContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 1440px) and (min-width: 700px) {
            padding: 0.5rem 0;
        }
        @media screen and (max-width:701px) {
            padding: 2rem 0; 
            font-size: 1.2rem;
        }
        .first{
            display: flex;
            margin-bottom: 12px;
            @media screen and (max-width:768px) {
                flex-wrap: wrap;
            }
        }
        .second{
            display: flex;
            @media screen and (max-width:768px) {
                flex-wrap: wrap;
            }
        }
        p{
            margin: 0 1rem;
        }
    }
}

.assiciatedWithMainContainer{
    padding: 2rem;
    background-color: #fff;
    text-align: center;
    @media screen and (max-width:768px) {
        padding: 2.5rem 1rem;
    }
    .heading{
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 2rem;
        @media screen and (max-width: 1440px) {
            font-size: 2.2rem;
        }
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }

    .associatedCard{
        border: 1px dashed #000;
        padding: 1rem; 
        display: flex; 
        width: 270px; 
        border-radius: 30px; 
        justify-content: center;
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            width: 220px;
        }
        @media screen and (max-width: 500px){ 
            width: 100%;
        }
    }
}

.carousel{
    ul{
    background-color: #fff;
    @media screen and (max-width: 500px){ 
        li{
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    }
}

.teamCarousel{
    ul{
        padding: 0;
    }
}

.modalTitle{
    color: #038232;
    font-weight: 700;
}

.modalBody{
    font-weight: 600;
}



// VedioAlongWithCarouselContainer

.videoAlongWIthCarouselContainer{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: flex-end;
    background-image: linear-gradient(rgba(27, 48, 70, 0.9), rgba(27, 48, 70, 0.9)), url(../../../utils/images/ItrVedioBg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 3rem;
    padding-bottom: 6rem;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        background-image: url(../../../utils/images/bottomCurve.png);
    }
    .vedioContainer{
    .solutionsVedio{
       border-radius: 10px;
        width: 500px;
        height: 400px;
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            width: 400px;
            height: 300px;
        }
        @media screen and (max-width:768px) {
            height: 300px;
            width: 100%;
            border-radius: 20px;
        }
    }
    @media screen and (max-width:600px) {
        width: 100%;
        padding: 10px;
    }
    }  
}
.miniCarouselContainer{
    margin-left: 60px;
    border-radius: 10px;
    width: 500px;
    height: 400px;
    .carouselItem{
        border-radius: 10px;
    }
    .carouselImg{
        width: 500px;
        height: 400px;
        border-radius: 10px;
    }

    @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 400px;
        height: 300px;
        .carouselImg{
            width: 400px;
        height: 300px;
        }
    }
    @media screen and (max-width:768px) {
        height: 300px;
        width: 100%;
        .carouselImg{
            height: 300px;
        width: 100%;
        }
    }
}


.aboutUsContainer{
    background-color: #f3ffee;
    display: flex;
    flex-direction: column;
    align-items: center;

    .mainHeading{
        font-size: 1.5rem;
        padding: 2rem;
        text-align: center;
        color: #fff;
        width: 100%;
        background-color: #154047;
        @media screen and (max-width:768px) {
            font-size: 1.3rem;
            padding: 1rem;
            height: 100px;
        }
    }

    .heading{
        margin-top: 30px;
        font-size: 2rem;
        font-weight: 800;
        @media screen and (max-width:768px) {
            font-size: 1.5rem;
        }
    }

    .descriptionContainer{
        display: flex;
        padding: 2rem;
        margin: 0;
        justify-content: space-around;
        @media screen and (max-width:768px) {
            flex-direction: column-reverse;
        }

        .description{
            width: 40%;
            font-size: 1.1rem;
            text-align: justify;
            @media screen and (max-width:768px) {
                width: 100%;
                font-size: 1rem;
            }
        }

        img{
            width: 40%;
            height: 400px;
            @media screen and (max-width:768px) {
                width: 100%;
                height: 300px;
                margin-bottom: 40px;
            }
        }
    }
}