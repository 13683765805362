.contactMainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #FAFAFA;
    padding-right: 15rem;
    @media screen and (max-width: 1800px){
        padding-right: 7rem;
    }
    .contactLeftContainer{
        padding: 3rem 0 0 5rem;
        height: 500px;
        width: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: linear-gradient(rgba(27, 48, 70, 0.7), rgba(27, 48, 70, 0.7)), url(../../../utils/images/ContactBackground.jpg);
        @media screen and (max-width:1023px) {
        width: 100%;
        padding: 2rem 1rem;
        margin-bottom: 3rem;
        .conatctInfo{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    }
    .contactHeading{
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 2rem;
        color: #fff;
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            font-size: 2.2rem;
        }
        @media screen and (max-width:768px) {
            font-size: 1.4rem;
            text-align: center;
        }
    }
    .contactDescription{
        color: #fff;
        font-size: 1.3em;
        margin-bottom: 8px;
        margin-top: 0;
        @media screen and (max-width: 1440px) {
            font-size: 0.8rem;
        }
        @media screen and (max-width:768px) {
            text-align: center;
        }
    }
    .contactInfo{
        padding-top: 1.5rem;
        color: #fff;
        font-size: 1.3rem;
        @media screen and (max-width: 768px){
            text-align: center;
        }
        @media screen and (max-width: 1440px){
            font-size: 0.9rem;
        }
        .mobile{
            margin-bottom: 0;
        }
        .mail{
            margin: 0;
            padding: 0;
        }

        .followLinkHeading{
            color: #adadb9;
            padding-top: 0.5rem;
            font-size: 1.5rem;
        }

        .followIcons{
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin-top: 10px;
            padding-left: 0;
            li{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;
                cursor: pointer;
                // @media screen and (max-width:768px) {
                //     margin-left: 10px;
                //     margin-right: 10px;
                // }
                img{
                    width: 70px;
                    height: 70px;
                    @media screen and (max-width: 1440px) {
                        width: 50px;
                        height: 50px;
                    }
                    @media screen and (max-width: 768px) {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
    @media screen and (max-width:1023px) {
        flex-direction: column;
        padding: 0;
    }
}

.contactStepMainContainer{
    .stepContainer{
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
        img{
            width: 130px;
            height: 130px;
        }
        .heading{
            font-size: 1.5rem;
            font-weight: 600;
        }
        .blue{
            color: #069797;
        }
        .description{
            width: 400px;
            height: 110px;
            font-size: 1.3rem;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            line-height: 1.5rem;
            padding: 1rem 2rem;
            margin-left: 1rem;
            box-shadow: 1px 1px 3px 2px #b8b7bb;
            border-radius: 20px;
            @media screen and (max-width:767px) {
                margin-left: 0;
                margin-bottom: 3rem;
                width: 100%;
            }
        }
        @media screen and (max-width: 1440px) {
            img{
                width: 100px;
                height: 100px;
            }
            .heading{
                font-size: 1.2rem;
            }
            .description{
                font-size: 0.9rem;
                width: 300px;
                height: 90px;
                border-radius: 10px;
            }
        }
    }
    @media screen and (max-width:767px) {
        padding: 0 2rem;
    }
}