.servicesMainContainer {
  padding: 4rem 10rem;
  background-color: #f3ffee;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    font-size: 2.5rem;
    font-weight: 700;

    @media screen and (max-width: 1440px) {
      font-size: 1.8rem;
      margin-top: 3rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .description {
    color: #696871;
    font-weight: 500;
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
    text-align: center;
  }
}

.servicesCardMainContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  justify-content: center;
  align-items: flex-start;
  background-color: #f3ffee;

  @media screen and (max-width: 768px) {
    height: auto;
  }
}

.servicesCard:nth-child(1),
.servicesCard:nth-child(4),
.servicesCard:nth-child(5),
.servicesCard:nth-child(7) {
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.servicesCard:nth-child(2),
.servicesCard:nth-child(3),
.servicesCard:nth-child(6) {
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.servicesCard {
  box-shadow: 8px 8px 4px 3px #979797;
  width: 510px;
  border: 2px solid #cbc9c9;
  background-color: #f5ffff;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  margin: 20px;
  justify-content: space-between;
  min-height: 550px;


  @media screen and (max-width: 768px) {
    position: block;
    top: 0;
    width: 100%;
    padding-top: 1.5rem;
    margin-left: 5px;
    margin-right: 5px;
  }

  .imageContainer {
    display: flex;
    padding-left: 2rem;
    align-items: center;
    padding-bottom: 1.5rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-left: 0;
    }
  }

  .image {
    width: 50px;
    height: 50px;
    // border: 1px solid black;
    border-radius: 50%;
  }

  .cardTitle {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    margin: 0;

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-top: 1rem;
      font-size: 1.1rem;
    }
  }

  .cardPrices {
    padding-left: 3rem;
    padding-bottom: 0.8rem;
    font-size: 1rem;
    font-weight: 500;
    color: #696871;
    margin: 0;
  }

  .cardListContainer {

    // max-height: 400px;
    @media screen and (max-width: 768px) {
      word-wrap: break-word;
      white-space: pre-line;
      width: 310px;
    }
  }

  .servicesCardContainer {
    width: 100%;
    padding: 0 3rem;

    @media screen and (max-width: 1440px) and (min-width: 767px) {
      width: 450px;
      padding: 0 1.5rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0 1rem;
      box-sizing: border-box;
    }

    .tick {
      width: 10px;
      height: 10px;
      padding: 8px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .solutionDescription {
      font-size: 1.1rem;
      font-weight: 500;
      color: #19191b;
      margin-top: 0;
      margin-bottom: 0;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
  }

  .cardButton {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: #06aead;
    width: 100%;
    padding: 1rem;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-sizing: border-box;
    margin-top: 2rem;
  }

  .btnContainer {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    .getMoreDetails,
    .watchVedio {
      margin-left: 10px;
      margin-right: 10px;
      border: 0;
      border-radius: 6px;
      background-color: #06aead;
      padding: 0.2rem 2rem;
      color: #fff;

      @media screen and (max-width: 767px) {
        padding: 0 0.5rem;
        font-size: 0.8rem;
      }

      @media screen and (max-width: 400px) {
        padding: 0 0.3rem;
        img{
          width: 40px;
          height: 30px;
        }
      }
    }

    .watchVedioBtn {
      border: 0;
      background-color: transparent;
      margin: 10px;
      color: white;
    }
  }


  @media screen and (max-width: 1440px) and (min-width: 767px) {
    .image {
      width: 60px;
      height: 40px;
    }

    .cardTitle {
      font-size: 1.1rem;
    }

    .cardButton {
      font-size: 1rem;
    }

    .cardListContainer {
      max-height: 300px;
    }
  }
}

.newServiceCard {
  min-height: 300px;
  width: 925px;
}

.servicesCard:hover {
  background-color: #06aead;
  color: white;

  .getMoreDetails,
  .watchVedioBtn,
  .watchVedio {
    background-color: white;
    color: black;
  }

  .solutionDescription {
    color: white;
  }
}

.solutionList {
  width: 0px;
}