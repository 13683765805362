.solutionInfoMainContainer{
    background-color:#FAFAFA;
    padding: 3rem 0 3rem 7rem;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
    @media screen and (max-width:1440px) {
        align-items: center;
        padding: 2rem 0 2rem 2.5rem;
    }
    @media screen and (max-width:768px) {
        padding: 2rem;
    }
}

.solutionInfoContainer{
    display: flex;
    padding-bottom: 3rem;
    justify-content: space-between;
    @media screen and (max-width:1024px) {
        flex-wrap: wrap;
    }
}
    

.infoContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 0 1 50%;
    @media screen and (max-width:1800px) {
        flex: 0 1 60%;
    }
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        flex: 0 1 70%;
    }
    @media screen and (max-width:768px) {
        flex: 100%;
    }
    .heading{ 
        font-size: 3rem;
        font-weight: 700;
        @media screen and (max-width: 1440px){
            font-size: 1.8rem;
            margin-bottom: 2rem;
            text-align: center;
        }
        @media screen and (max-width: 768px){
            font-size: 1.4rem;
        }
    }
}

.mobileInputContainer{
    display: flex;
    width: 600px;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
    border: 2px solid #e7ecec;
    margin-top: 2rem;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 470px;
    }
    @media screen and (max-width:768px) {
        width: 100%;
    }
}
.mobileInputContainer:hover{
    border: 2px solid #d7dbdb;
}

.mobileInputContainer:active{
    border: 2px solid #d7dbdb;
}

.mobileInput{
    border: 0;
    outline: 0;
    width: 300px;
    height: 40px;
    font-size: 1.3rem;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        height: 30px;
        font-size: 1rem;
    }
    @media screen and (max-width:768px) {
        font-size: 1.1rem;
        width: 100%;
        height: 30px;
    }
}

.errorBorder{
    border: 2px solid #ff0000;
}

.errorMsg{
    color: #ff0000;
    height: 10px;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    padding-left: 10px;
}

.mobileInputButton{
    background-color: #06aead;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 5rem;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        padding: 0.5rem 4rem;
        font-size: 1.2rem;
    }
    @media screen and (max-width:768px) {
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }
}
.mobileInputButton:hover{
    background-color: #039393;
}

.solutionListContainer{
    display: flex;
    flex-wrap: wrap;
}

.solutionList{
    display: flex;
    align-items: center;
    width:400px;
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        width: 330px;
    }
    @media screen and (max-width:768px) {
        width: 100%;
    }
    & p{
        margin-top: 10px;
        margin-bottom:10px;
    }

    .tick{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 13px;
        height: 13px;
        padding: 10px;
        border-radius: 50%;
        background-color: #d9e6f3;
        color: #06aead;
        margin-right: 15px;
        box-sizing: content-box;
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            padding: 10px;
            width: 10px;
            height: 10px;
        }
    }

    .tick::before{
        content: '✔';
    }
    
    .solutionDescription{
        font-size: 1rem;
        font-weight: 500;
        text-align: start;
        @media screen and (max-width: 1440px) {
            font-size: 0.9rem;
        }
        @media screen and (max-width:768px) {
            text-align: start;
        }
    }
}

.imageContainer{
    .solutionsVedio{
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        width: 700px;
        height: 500px;
        @media screen and (max-width:1800px) {
            width: 550px;
        }
        @media screen and (max-width: 1440px) and (min-width: 767px) {
            width: 500px;
            height: 300px;
        }
        @media screen and (max-width:768px) {
            height: 270px;
            width: 105%;
            border-radius: 20px;
            margin-top: 2rem;
        }
    }
}


