.offerContainer{
    padding: 5rem 10rem;
    @media screen and (max-width: 1800px) {
        padding: 5rem 0 5rem 5rem;
    }
    @media screen and (max-width: 1440px) and (min-width: 767px) {
        padding: 3rem 0 3rem 3rem;
      }
    @media screen and (max-width:768px) {
        padding: 5rem 1rem;
    }
    .offerHeading{
        font-size: 3rem;
        font-weight: 700;
        margin: 0;
        @media screen and (max-width:1440px) {
           font-size: 2.2rem;
        }
         @media screen and (max-width:768px) {
           font-size: 1.4rem;
        }
    }
    .offerDescription{
        font-size: 1.1rem;
        font-weight: 500;
        color: #6f6f77;
        margin-bottom: 2rem;
        @media screen and (max-width:1440px) {
            font-size: 0.8rem;
         }
    }
}

.card{
    display: flex;
    width: 500px;
    @media screen and (max-width:768px) {
        width: 100%;
     }
    .heading{
        background-color: #06aead;
        padding: 0.8rem;
        border-radius: 50%;
        color: #fff;
        width: 30px;
        min-width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        box-sizing: content-box;
        @media screen and (max-width:1440px) {
            padding: 0.3rem;
         }
    }
    .subHeading{
        color: #000;
        font-size: 1.3rem;
        font-weight: 700;
        margin-bottom: 0;
        @media screen and (max-width:1440px) {
            font-size: 1rem;
         }
    }
    .listItem{
        font-size: 1.1rem;
        color: #6f6f77;
        font-weight: 500;
        margin-top: 8px;
        @media screen and (max-width:1440px) {
            font-size: 0.8rem;
         }
    }
}

.stepCardContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5rem;
    @media screen and (max-width:768px) {
        padding: 5rem 1rem;
     }
    .stepBreak{
        border: 1px dashed #000;
        width: 30px;
        height: 0px;
        @media screen and (max-width:768px) {
            width: 0;
            height: 40px;
            margin: 0;
         }
    }
    .cardContainer{
        border: 2px dashed #000;
        text-align: center;
        width: 350px;
        height: 200px;
        margin: 0;
        @media screen and (max-width:768px) {
            width: 100%;
         }
    }
    .cardBlueContainer{
        border: 2px dashed  #06aead;
        text-align: center;
        width: 350px;
        height: 200px;
        margin: 0;
        @media screen and (max-width:768px) {
            width: 100%;
         }
    }
    .cardBlueTitle{
        color :  #06aead;
    }
    .cardDescription{
        font-size: 1.1rem;
        color: #000;
    }
}

.offerRightContentMainContainer{
    background-color: #d8e1e5;
    display: flex;
    justify-content: center;
    .offerImageContainer{
        display: flex;
        align-items: flex-end;
        padding-top: 5rem;
        img{
            height: 700px;
            @media screen and (max-width: 1440px) and (min-width: 767px) {
               width: 100%;
               height: 500px;
             }
        }
    }
    .offerContainer{
        .offerHeading{
            padding-bottom: 0.7rem;
        }    
    }

    @media screen and (max-width:768px) {
        flex-direction: column;
        .offerImageContainer{
            img{
                height: auto;
                width: 100%;
            }
        }
     }
    
}
.offerRightCard{
    width: 700px;
    @media screen and (max-width: 1440px) {
        width: 500px;
        .heading{
            font-size: 1rem;
        }
     }
    @media screen and (max-width:768px) {
        width: auto;
    }
}